/* eslint-disable jsx-a11y/aria-role */
import React from 'react';
import { func, number, string, bool } from 'prop-types';
import classnames from 'classnames';
import ThemeProvider from '@nutkit/theme';
import { useBreakpoint, breakpoints, breakpointDirections, useTokens } from '@nutkit/react-utils';
import Amount, { amountTypes, amountStyle, amountSizes } from '@nutkit/component-amount';
import Section, { stackSpacing } from '@nutkit/component-section';
import DescriptionBlock, {
  descriptionBlockAlignments,
  DescriptionBlockWithModal,
  DescriptionBlockWithTooltip
} from '@nutkit/component-description-block';
import { Container, Row, Col } from '@nutkit/component-grid';
import Align, { alignItemsRules, justifyContentRules, directionRules } from '@nutkit/component-align';
import Loading, { loadingSizes, loadingInlinePadding } from '@nutkit/component-loading';
import { Trans } from '@nm-utils-lib-web/translations';
import DateFormat from '@nutkit/component-date-format';
import { Text, textStyles, textWeights, tagNames, textAligns } from '@nutkit/component-text';
import Tooltip, { tooltipPlacements, TooltipWithTrigger } from '@nutkit/component-tooltip';
import { isNutmegGBRBigBear, isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';
import { TimeWeightedReturnContent, TotalContributionsContent } from '@nm-portfolio-lib-web/common/components';
import { MODAL_INFO_EVENT_TYPES, MODAL_INFO_EVENTS, MODAL_INFO_CONTEXTS } from '@nm-portfolio-lib-web/common/events';

import { getPerformancePrefix, toFixedPercent, displayPerformanceValue } from '../../helpers';

import styles from './PortfolioSummaryOverview.module.scss';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioSummary.labels';
const TRACKING_LOCATION = 'portfolio';

const PortfolioSummaryOverview = ({
  className,
  netContributions,
  portfolioValue,
  customerFirstName,
  returnValue,
  simpleReturnPercent,
  timeWeightedReturnPercent,
  lastUpdatedAt,
  twrrExcluded,
  twrrLastUpdatedAt,
  isCustomerPerformanceSummaryLoading,
  customerPerformanceSummaryError,
  'data-qa': dataQa,
  t
}) => {
  const { matchesCondition: isDesktop } = useBreakpoint(breakpoints.LG, breakpointDirections.UP);
  const { tokens } = useTokens();
  const containerClassName = classnames(styles.PortfolioSummaryOverview, className);
  const blocksAlignment = isDesktop ? descriptionBlockAlignments.LEFT : descriptionBlockAlignments.CENTER;
  const shouldShowTWRRLastUpdatedAt = twrrLastUpdatedAt && lastUpdatedAt !== twrrLastUpdatedAt;
  const isExcludedFromTWRR = timeWeightedReturnPercent === undefined || twrrExcluded;
  // TODO: replace this solution once we have another way for the content alignment
  const containerStyle = {
    marginBottom: isNutmegGBRBigBear() ? '5px' : '-1px'
  };

  return (
    <div className={containerClassName} data-qa={dataQa}>
      <Align
        direction={isDesktop ? directionRules.ROW : directionRules.COLUMN}
        justifyContent={isDesktop ? justifyContentRules.SPACE_BETWEEN : justifyContentRules.CENTER}
        alignItems={alignItemsRules.CENTER}
      >
        <Text align={!isDesktop ? textAligns.CENTER : undefined} noStack textWeight={textWeights.MEDIUM}>
          {t(`${TRANSLATION_NAMESPACE}.portfolioValue.label`, { customerFirstName })}
        </Text>
        {lastUpdatedAt && (
          <Text noStack align={!isDesktop ? textAligns.CENTER : undefined} textStyle={textStyles.TEXT_2}>
            <Tooltip message={t(`${TRANSLATION_NAMESPACE}.lastUpdatedAt.tooltip`)} placement={tooltipPlacements.BOTTOM}>
              {t(`${TRANSLATION_NAMESPACE}.lastUpdatedAt.label`)}{' '}
              <DateFormat
                date={lastUpdatedAt}
                options={{
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                }}
              />
            </Tooltip>
          </Text>
        )}
      </Align>
      <Align alignItems={isDesktop ? alignItemsRules.END : undefined}>
        <Section stackSpacing={!isDesktop ? stackSpacing.MD : undefined}>
          <Text
            noStack
            align={!isDesktop ? textAligns.CENTER : undefined}
            aria-label={t(`${TRANSLATION_NAMESPACE}.portfolioValue.ariaLabel`, { portfolioValue })}
            role="text"
          >
            <Amount
              value={displayPerformanceValue(portfolioValue)}
              size={amountSizes.XXL}
              style={amountStyle.CURRENCY}
              data-qa={`${dataQa}__portfolio-value`}
              maxDecimals={0}
              minDecimals={0}
              noLineHeight
            />
          </Text>
        </Section>
        <Container fluid={!!isDesktop} style={containerStyle} data-qa={`${dataQa}__portfolio_container`}>
          <Row>
            <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: 'auto' }}>
              <div
                aria-label={t(`${TRANSLATION_NAMESPACE}.netContributions.ariaLabel`, { netContributions })}
                role="text"
              >
                <DescriptionBlockWithModal
                  align={blocksAlignment}
                  noStack={isDesktop}
                  label={t(`${TRANSLATION_NAMESPACE}.netContributions.label`)}
                  modalTriggerAriaLabel={t(`${TRANSLATION_NAMESPACE}.netContributions.modalTriggerAriaLabel`)}
                  data-qa={`${dataQa}__net-contributions`}
                  content={
                    <ThemeProvider>
                      <TotalContributionsContent />
                    </ThemeProvider>
                  }
                  cta={{ text: t(`${TRANSLATION_NAMESPACE}.netContributions.ctaButtonText`) }}
                  onOpen={() =>
                    MODAL_INFO_EVENTS[MODAL_INFO_EVENT_TYPES.TOTAL_CONTRIBUTIONS_INFO_CLICKED]({
                      location: MODAL_INFO_CONTEXTS[TRACKING_LOCATION]
                    })
                  }
                  description={
                    <Amount
                      value={displayPerformanceValue(netContributions)}
                      style={amountStyle.CURRENCY}
                      maxDecimals={0}
                      minDecimals={0}
                    />
                  }
                />
              </div>
            </Col>
            <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: 'auto' }}>
              <div
                aria-label={t(`${TRANSLATION_NAMESPACE}.returnValue.ariaLabel`, {
                  performancePrefix: getPerformancePrefix(returnValue),
                  returnValue
                })}
                role="text"
              >
                <DescriptionBlock
                  align={blocksAlignment}
                  noStack={isDesktop}
                  label={t(`${TRANSLATION_NAMESPACE}.returnValue.label`)}
                  description={
                    <Amount
                      value={displayPerformanceValue(returnValue)}
                      type={amountTypes.PERFORMANCE}
                      style={amountStyle.CURRENCY}
                      maxDecimals={0}
                      minDecimals={0}
                    />
                  }
                />
              </div>
            </Col>
            <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: 'auto' }}>
              <div
                aria-label={t(`${TRANSLATION_NAMESPACE}.simpleReturnPercent.ariaLabel`, {
                  performancePrefix: getPerformancePrefix(simpleReturnPercent),
                  simpleReturnPercent: toFixedPercent(simpleReturnPercent)
                })}
                role="text"
              >
                <DescriptionBlockWithTooltip
                  align={blocksAlignment}
                  noStack={isDesktop}
                  label={t(`${TRANSLATION_NAMESPACE}.simpleReturnPercent.label`)}
                  data-qa={`${dataQa}__simple-return`}
                  description={
                    isCustomerPerformanceSummaryLoading && !customerPerformanceSummaryError ? (
                      <Loading
                        inline
                        size={loadingSizes.SM}
                        inlinePaddingPosition={loadingInlinePadding.LEFT}
                        data-qa={`${dataQa}__simple-return__loading`}
                      />
                    ) : (
                      <Amount
                        value={displayPerformanceValue(simpleReturnPercent)}
                        type={amountTypes.PERFORMANCE}
                        style={amountStyle.PERCENT}
                        data-qa={`${dataQa}__simple-return__value`}
                      />
                    )
                  }
                />
              </div>
            </Col>
            {!isExcludedFromTWRR && (
              <Col xs={{ size: '6' }} md={{ size: '3' }} lg={{ size: 'auto' }}>
                <div
                  aria-label={
                    shouldShowTWRRLastUpdatedAt
                      ? t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.ariaLabelWithDate`, {
                          performancePrefix: getPerformancePrefix(timeWeightedReturnPercent),
                          timeWeightedReturnPercent: toFixedPercent(timeWeightedReturnPercent),
                          twrrLastUpdatedAt: new Date(twrrLastUpdatedAt).toLocaleDateString(tokens.NK_LOCALE, {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })
                        })
                      : t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.ariaLabel`, {
                          performancePrefix: getPerformancePrefix(timeWeightedReturnPercent),
                          timeWeightedReturnPercent: toFixedPercent(timeWeightedReturnPercent)
                        })
                  }
                  role="text"
                >
                  <DescriptionBlockWithModal
                    align={blocksAlignment}
                    noStack={isDesktop}
                    label={t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.label`)}
                    modalTriggerAriaLabel={t(
                      `${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.modalTriggerAriaLabel`
                    )}
                    data-qa={`${dataQa}__time-weighted-return`}
                    content={
                      <ThemeProvider>
                        <TimeWeightedReturnContent
                          trackingLocation={MODAL_INFO_CONTEXTS[TRACKING_LOCATION]}
                          isTrackingLinkEnabled={!isJohnLewisFinanceGBR()}
                        />
                      </ThemeProvider>
                    }
                    cta={{ text: t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.ctaButtonText`) }}
                    onOpen={() =>
                      MODAL_INFO_EVENTS[MODAL_INFO_EVENT_TYPES.TWRR_INFO_CLICKED]({
                        location: MODAL_INFO_CONTEXTS[TRACKING_LOCATION]
                      })
                    }
                    description={
                      isCustomerPerformanceSummaryLoading && !customerPerformanceSummaryError ? (
                        <Loading
                          inline
                          size={loadingSizes.SM}
                          inlinePaddingPosition={loadingInlinePadding.LEFT}
                          data-qa={`${dataQa}__time-weighted-return__loading`}
                        />
                      ) : (
                        <TooltipWithTrigger
                          id="time-weighted-label"
                          data-qa={`${dataQa}__time-weighted-return__tooltip`}
                          message={
                            shouldShowTWRRLastUpdatedAt ? (
                              <Trans
                                components={[
                                  <br />,
                                  <Text tagName={tagNames.SPAN} textStyle={textStyles.TEXT_2} noStack>
                                    <DateFormat
                                      data-qa={`${dataQa}__time-weighted-return__tooltip_date`}
                                      date={twrrLastUpdatedAt}
                                      options={{
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric'
                                      }}
                                    />
                                  </Text>
                                ]}
                                defaults={`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.infoWithDate`}
                              />
                            ) : (
                              t(`${TRANSLATION_NAMESPACE}.timeWeightedReturnPercent.info`)
                            )
                          }
                        >
                          <Amount
                            value={displayPerformanceValue(timeWeightedReturnPercent)}
                            type={amountTypes.PERFORMANCE}
                            style={amountStyle.PERCENT}
                            data-qa={`${dataQa}__time-weighted-return__value`}
                          />
                        </TooltipWithTrigger>
                      )
                    }
                  />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </Align>
    </div>
  );
};

PortfolioSummaryOverview.defaultProps = {
  className: undefined,
  'data-qa': 'portfolio-summary-overview',
  portfolioValue: undefined,
  netContributions: undefined,
  returnValue: undefined,
  simpleReturnPercent: undefined,
  timeWeightedReturnPercent: undefined,
  lastUpdatedAt: undefined,
  twrrLastUpdatedAt: undefined,
  isCustomerPerformanceSummaryLoading: undefined,
  twrrExcluded: false,
  customerPerformanceSummaryError: undefined
};

PortfolioSummaryOverview.propTypes = {
  className: string,
  netContributions: number,
  'data-qa': string,
  portfolioValue: number,
  customerFirstName: string.isRequired,
  returnValue: number,
  simpleReturnPercent: number,
  t: func.isRequired,
  timeWeightedReturnPercent: number,
  twrrExcluded: bool,
  lastUpdatedAt: string,
  twrrLastUpdatedAt: string,
  isCustomerPerformanceSummaryLoading: bool,
  customerPerformanceSummaryError: bool
};

export default PortfolioSummaryOverview;
