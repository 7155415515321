import noMatchLanguage from '@nm-utils-lib-web/no-match/languages/nutmeg-gbr-bigbear.json';
import { organisationHeaderTranslations } from '@nm-ui-shared-lib-web/organisation-header/languages/nutmeg';
import { organisationFooterTranslations } from '@nm-ui-shared-lib-web/organisation-footer/languages/nutmeg';
import { sideMenuTranslations } from '@nm-ui-shared-lib-web/side-menu/languages';
import { nutmeg as riskQuestionnaire } from '@nm-customer/risk-questionnaire/languages/';
import { nutmeg as riskProfile } from '@nm-customer/risk-profile/languages/';

import annualReview from './annual-review.json';
import common from './common.json';
import details from './details.json';
import isaAllowance from './isa-allowance.json';
import isaSettings from './isa-settings.json';
import portfolioDetails from './portfolio-details.json';
import portfolioDashboard from './portfolio-dashboard.json';
import transactionsHistory from './transactions-history.json';
import securityQuestions from './security-questions.json';
import paymentsAndTransfers from './payments-and-transfers.json';
import featureIntro from './feature-intro.json';
import promptCards from './prompt-cards.json';
import portfolioSummary from './portfolio-summary.json';

export default {
  dashboard: {
    annualReview,
    common: {
      ...common,
      ...organisationHeaderTranslations,
      ...organisationFooterTranslations,
      ...noMatchLanguage
    },
    details,
    isaAllowance,
    isaSettings,
    portfolioDashboard,
    portfolioDetails,
    portfolioSummary,
    transactionsHistory,
    securityQuestions,
    paymentsAndTransfers,
    featureIntro,
    promptCards
  },
  ...riskQuestionnaire,
  ...sideMenuTranslations,
  ...riskProfile
};
