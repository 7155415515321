import React from 'react';
import { func, string, object, shape, oneOfType, bool } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Heading, headingLevels, Text } from '@nutkit/component-text';
import { RadioGroup, RadioInput } from '@nutkit/component-radio';
import Loading from '@nutkit/component-loading';
import { JourneyNavigation } from '@nm-portfolio-lib-web/common/components';
import useGetTaxYearPeriod from '@nm-portfolio-lib-web/common/hooks/useGetTaxYearPeriod';

const TRANSLATION_NAMESPACE = 'payments.transferSISAOpen';
// Open choices. It's not possible to store booleans as values
// for input[type="radio"]. These values are aliases for them
const POSITIVE_VALUE = 'YES';
const NEGATIVE_VALUE = 'NO';

const toChoice = value => {
  if (typeof value === 'boolean') {
    return value ? POSITIVE_VALUE : NEGATIVE_VALUE;
  }

  return value;
};

const TransferSISAOpen = props => {
  const { t, 'data-qa': dataQa, openISA, setOpenISA, journeyNavigation: journeyControls, userUuid } = props;
  const { taxYear, isLoading } = useGetTaxYearPeriod({ customerUuid: userUuid });
  const taxYearOptions = { taxYear, interpolation: { escapeValue: false } };
  const choicesNamespace = `${TRANSLATION_NAMESPACE}.content.choices`;
  const title = t(`${TRANSLATION_NAMESPACE}.headers.title`);
  const subTitle = t(`${TRANSLATION_NAMESPACE}.headers.subTitle`);
  const description = t(`${TRANSLATION_NAMESPACE}.content.description`, taxYearOptions);
  const choicePositiveName = t(`${choicesNamespace}.positive.name`, taxYearOptions);
  const choicePositiveDescription = t(`${choicesNamespace}.positive.description`);
  const choiceNegativeName = t(`${choicesNamespace}.negative.name`, taxYearOptions);
  const choiceNegativeDescription = t(`${choicesNamespace}.negative.description`);
  const value = toChoice(openISA);

  return (
    <>
      <Heading>{title}</Heading>
      {isLoading && <Loading />}
      {!isLoading && (
        <Panel>
          <Heading level={headingLevels.TWO}>{subTitle}</Heading>

          <Text>{description}</Text>

          <RadioGroup
            name="transfer-sisa-open-choice"
            onChange={event => setOpenISA(event.target.value === POSITIVE_VALUE)}
            value={value}
            data-qa={`${dataQa}-open-choice`}
          >
            <RadioInput value={POSITIVE_VALUE} label={choicePositiveName} data-qa={`${dataQa}-open-choice-positive`}>
              {choicePositiveDescription}
            </RadioInput>

            <RadioInput value={NEGATIVE_VALUE} label={choiceNegativeName} data-qa={`${dataQa}-open-choice-negative`}>
              {choiceNegativeDescription}
            </RadioInput>
          </RadioGroup>

          <JourneyNavigation
            {...journeyControls}
            continueButton={{ ...journeyControls.continueButton, disabled: !value }}
          />
        </Panel>
      )}
    </>
  );
};

TransferSISAOpen.propTypes = {
  userUuid: string.isRequired,
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  openISA: bool,
  setOpenISA: func.isRequired,
  t: func.isRequired,
  'data-qa': string
};

TransferSISAOpen.defaultProps = {
  openISA: null,
  'data-qa': 'transfer-sisa-open'
};

export default TransferSISAOpen;
