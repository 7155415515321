import { func } from 'prop-types';
import { useParams } from 'react-router';

export const TransactionHistoryRedirect = ({ to }) => {
  const params = useParams();

  global.location.assign(to(params));

  return null;
};

TransactionHistoryRedirect.propTypes = {
  to: func.isRequired
};
