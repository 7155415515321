import React from 'react';
import { func, string, object, shape, oneOfType, bool } from 'prop-types';
import Panel from '@nutkit/component-panel';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { Heading, headingLevels, Text } from '@nutkit/component-text';
import { RadioGroup, RadioInput } from '@nutkit/component-radio';
import { TRANSFER_OPTIONS } from '@nm-payments/isa-transfer/constants/TransferOptions';
import { JourneyNavigation } from '@nm-portfolio-lib-web/common/components';

const TRANSLATION_NAMESPACE = 'payments.transferSISA';

const TransferSISA = props => {
  const { t, 'data-qa': dataQa, transfer, setTransfer, journeyNavigation: journeyControls } = props;
  const title = t(`${TRANSLATION_NAMESPACE}.headers.title`);
  const subTitle = t(`${TRANSLATION_NAMESPACE}.headers.subTitle`);
  const description = t(`${TRANSLATION_NAMESPACE}.content.description`);

  return (
    <>
      <Heading>{title}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO}>{subTitle}</Heading>
        <Text>{description}</Text>
        <Notification data-qa="transfer-info" level={notificationLevels.INFORMATION} dismissable={false}>
          {t(`${TRANSLATION_NAMESPACE}.content.informationOpenISA`)}
        </Notification>

        <RadioGroup
          name="transfer-choice"
          onChange={event => setTransfer(event.target.value)}
          value={transfer}
          data-qa={`${dataQa}-transfer-choice`}
        >
          <RadioInput
            value={TRANSFER_OPTIONS.FULL}
            label={t(`${TRANSLATION_NAMESPACE}.content.fullTransferTitle`)}
            data-qa={`${dataQa}-select-full`}
          >
            {t(`${TRANSLATION_NAMESPACE}.content.fullTransferDescription`)}
          </RadioInput>
          <RadioInput
            value={TRANSFER_OPTIONS.PARTIAL}
            label={t(`${TRANSLATION_NAMESPACE}.content.partialTransferTitle`)}
            data-qa={`${dataQa}-select-partial`}
          >
            {t(`${TRANSLATION_NAMESPACE}.content.partialTransferDescription`)}
          </RadioInput>
        </RadioGroup>

        <JourneyNavigation
          {...journeyControls}
          continueButton={{ ...journeyControls.continueButton, disabled: !transfer }}
        />
      </Panel>
    </>
  );
};

TransferSISA.propTypes = {
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  setTransfer: func.isRequired,
  t: func.isRequired,
  transfer: string,
  'data-qa': string
};

TransferSISA.defaultProps = {
  'data-qa': 'transfer-sisa',
  transfer: null
};

export default TransferSISA;
