import React from 'react';
import { Text, Heading, headingLevels } from '@nutkit/component-text';
import { func, string, object, shape, oneOfType, bool } from 'prop-types';
import TransferFull from '@nm-payments/isa-transfer/components/TransferFull';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import Panel from '@nutkit/component-panel';
import TransferDownloadForm from '@nm-payments/isa-transfer/components/TransferDownloadForm';
import { FULL_TRANSFER_PDF } from '@nm-payments/isa-transfer/constants/Resources';
import { JourneyNavigation } from '@nm-portfolio-lib-web/common/components';

import { isManualTransfer } from '../../journey/helpers';

import TransferTermsSISA from './TransferTermsSISA';

const TRANSLATION_NAMESPACE = 'payments.transferFormSISA';

const TransferSISAFullTransfer = props => {
  const {
    t,
    'data-qa': dataQa,
    userUuid,
    userNino,
    setTransferData,
    journeyNavigation,
    transferData,
    onboarding,
    location: { search }
  } = props;
  const prevRef = React.useRef();

  React.useEffect(() => {
    prevRef.current = location;
  });

  const prevLocation = prevRef.current;

  React.useEffect(() => {
    if (transferData && transferData.status) {
      // Continuing navigation only when we changed transferData [ Manual/Auto/Other]
      if (location !== prevLocation) {
        setTransferData({});

        return;
      }

      journeyNavigation.continueButton.onClick();
    }
  }, [journeyNavigation.continueButton, prevLocation, setTransferData, transferData]);

  const mainTitle = t(`${TRANSLATION_NAMESPACE}.title`);
  const panelTitle = t(`${TRANSLATION_NAMESPACE}.subtitle`);
  const checkboxLabel = t(`${TRANSLATION_NAMESPACE}.checkboxLabel`);
  const beforeForm = <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>;
  const ISATransferTerms = props => (
    <TransferTermsSISA t={t} data-qa={dataQa} translationNamespace={TRANSLATION_NAMESPACE} {...props} />
  );
  const fieldsConfig = {
    currentProviderId: {
      label: t(`${TRANSLATION_NAMESPACE}.fields.currentProvider.label`),
      placeholder: t(`${TRANSLATION_NAMESPACE}.fields.currentProvider.placeholder`),
      initial: null,
      errorMessage: t(`${TRANSLATION_NAMESPACE}.fields.currentProvider.errorMessage`)
    },
    accountReference: {
      label: t(`${TRANSLATION_NAMESPACE}.fields.accountReference.label`),
      initial: '',
      errorMessage: t(`${TRANSLATION_NAMESPACE}.fields.accountReference.errorMessage`),
      validationErrorMessage: t(`${TRANSLATION_NAMESPACE}.fields.accountReference.validationErrorMessage`)
    },
    valueOfIsa: {
      label: t(`${TRANSLATION_NAMESPACE}.fields.valueOfIsa.label`),
      initial: null,
      errorMessage: t(`${TRANSLATION_NAMESPACE}.fields.valueOfIsa.errorMessage`)
    },
    contributionsThisYear: {
      label: t(`${TRANSLATION_NAMESPACE}.fields.contributionsThisYear.label`),
      initial: null,
      errorMessage: t(`${TRANSLATION_NAMESPACE}.fields.contributionsThisYear.errorMessage`)
    },
    termsAndConditionsAccepted: {
      label: t(`${TRANSLATION_NAMESPACE}.fields.termsAndConditions.label`),
      initial: false,
      errorMessage: t(`${TRANSLATION_NAMESPACE}.fields.termsAndConditions.errorMessage`)
    },
    submit: {
      label: t(`${TRANSLATION_NAMESPACE}.fields.submit.otherContinueButtonLabel`)
    }
  };
  const journeyControls = { ...journeyNavigation, backButton: null };
  const commonProps = {
    onSubmit: setTransferData,
    title: panelTitle,
    beforeForm,
    wrapperType: wrapperTypes.SISA,
    checkboxText: checkboxLabel,
    journeyNavigation: journeyControls,
    fieldsConfig,
    dataQa,
    userNino,
    userUuid,
    ISATransferTerms
  };

  return (
    <>
      <Heading>{mainTitle}</Heading>
      {isManualTransfer(search) ? (
        <Panel>
          <Heading level={headingLevels.TWO}>{panelTitle}</Heading>
          <TransferDownloadForm pdfUrl={FULL_TRANSFER_PDF} onboarding={onboarding} />
          <JourneyNavigation dataQa={`${dataQa}-navigation`} {...journeyControls} />
        </Panel>
      ) : (
        <TransferFull {...commonProps} />
      )}
    </>
  );
};

TransferSISAFullTransfer.propTypes = {
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  location: shape({}).isRequired,
  userUuid: string.isRequired,
  userNino: string.isRequired,
  setTransferData: func.isRequired,
  onboarding: string,
  transferData: shape({}),
  t: func.isRequired,
  'data-qa': string
};

TransferSISAFullTransfer.defaultProps = {
  'data-qa': 'transfer-sisa-form',
  transferData: null,
  onboarding: ''
};

export default TransferSISAFullTransfer;
